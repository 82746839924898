.info-section{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: rgba(0, 0, 0, 0.72);
    border-radius: 3px;
    padding: 50px 0px;
    flex-wrap: wrap;
}
.info-overlay{
    width: 100%;
    height: 100vh;
    background-image: url(../../../public/Which-Type-of-Investment-Has-the-Highest-Risk.jpg);
    background-position:top;
    background-size: cover;
    background-repeat: no-repeat;
    object-fit: cover;
    position: fixed;
    z-index: -1;
    top: 0;
}
.info-card{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 20px 0px;
    min-width: 200px;
}
.info-card svg{
    font-size: 3rem;
    color: rgba(255, 255, 255, 0.753);
    font-weight: lighter;
    margin-bottom: 10px;
}
.small-thin-line{
    margin: 20px 0px;
    width: 50px;
    height: 2px;
    background-color: whitesmoke;
}
.info-card h3{
    color: white;
    font-size: 1.2rem;
    font-family: 'Red Hat Display';
}
.info-card h2{
    letter-spacing: 1px;
    /* font-weight: 500; */
    color: white;
    font-size: 3rem;
    font-family: 'Red Hat Display','montserrat','lato',system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
@media (max-width: 990px) and (min-width: 768px){
   
}
@media screen and (max-width: 767px){
    .info-overlay{
        background-position: top;
    }
    .info-card svg{
        font-size: 3.5rem;
    }
    .info-card h3{
        color: white;
        font-size: 1.1rem;
    }
    .info-card h2{
        letter-spacing: 1px;
        font-weight: 500;
        color: white;
        font-size: 1.5rem;
        font-family: montserrat,lato,system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }
    .info-section{
        height: auto;
    }
}